import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List, Span } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="text-center">
                  Ad Formats and Rates
                </Title>
                <Text variant="small" mb="5px">
                  <b>Pricing Options:</b> Below is our pricing table for various ad formats:
                </Text>

                <Box mt={["40px", null, "30px"]}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Ad Format</th>
                        <th>Description</th>
                        <th>Frequency</th>
                        <th>Ideal For</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Interstitial</td>
                        <td>Full screen ad shown between page transitions. Supports images and short videos.</td>
                        <td>Rotated with other ads based on ad slot.</td>
                        <td>High-ticket products and services</td>
                        <td>$445/month</td>
                      </tr>

                      <tr>
                        <td>Landing Banner</td>
                        <td>Displayed at the top of the app's front page.</td>
                        <td>Rotated with other ads based on ad slot.</td>
                        <td>All businesses</td>
                        <td>$395/month</td>
                      </tr>

                      <tr>
                        <td>Channel Banner</td>
                        <td>Displayed in newsfeed, business, and housing channels between listings.</td>
                        <td>Rotated with other ads based on ad slot.</td>
                        <td>All businesses</td>
                        <td>$295/month</td>
                      </tr>

                      <tr>
                        <td>Bottom Banner</td>
                        <td>Displayed at the bottom of each page where an ad slot is available.</td>
                        <td>Rotated with other ads based on ad slot.</td>
                        <td>All businesses</td>
                        <td>$205/month</td>
                      </tr>
                    </tbody>
                  </Table>
                </Box>

                <Box mt={["40px", null, "30px"]}>
                  <Text variant="small" mb="10px">
                    <b>Get Started:</b> To start your ad campaign with Kesto Ads, simply select your preferred ad format and contact us to set up your campaign. With our detailed analytics, you can track the performance of your ads and make informed decisions to maximize your return on investment.
                  </Text>

                </Box>

                <Box mt={["40px", null, "30px"]}>
                  <Text variant="small" mb="5px">
                    <b>Contact Us:</b> For more information or to begin your advertising campaign, please reach out to our sales team at <a href="dial:650-924-2374"><Span color="primary">650-924-2374</Span></a>  or email <a href="mailto:support@kesto.io"><Span color="primary">  support@kesto.io</Span></a>
                  </Text>
                  
                </Box>
                
                <Box mt={["40px", null, "30px"]}>
                  <Text variant="small" mb="30px">
                    Kesto Ads offers a unique and effective way to reach a dedicated and engaged audience within the Ethiopian and Eritrean communities. Take advantage of our custom ad formats and detailed analytics to drive your business goals forward during our upcoming major events in July 2024.
                  </Text>
                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
